<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <!-- <el-cascader ref="firstCascader" :options="treeData" :props="defaultProps" v-model="searchForm.class_id"
          :show-all-levels="false" placeholder="请选择班级" clearable filterable style="width: 150px;margin-right:10px" size="small">
        </el-cascader>-->

        <tchtree v-model="searchForm.class_ids"></tchtree>

        <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:250px" clearable>

        </el-input>

        <el-date-picker v-if="signLevel == 'week'" @change="changeDate" size="small" v-model="searchForm.year_week"
          style="width: 160px;margin-left:10px" type="week" format="yyyy 第 WW 周" placeholder="选择周">
        </el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="handleSearch">搜索</el-button>
      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button icon="el-icon-download" size="small" @click="exportExcel">导出</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="60px"></el-table-column>
      <el-table-column label="学号" prop="stu_code" width="135"></el-table-column>
      <el-table-column label="学生姓名" prop="stu_name" width="100px"></el-table-column>
      <el-table-column label="所在班级" prop="class_name" show-overflow-tooltip width="150px"></el-table-column>
      <el-table-column label="系部" prop="class_name" show-overflow-tooltip min-width="150px">
        <template slot-scope="scope">{{ scope.row.yx_name }} {{ scope.row.major_name }}</template>
      </el-table-column>
      <el-table-column label="电话" prop="phone" min-width="100px"></el-table-column>
      <el-table-column label="紧急联系人" prop="jhr1name" min-width="100px"></el-table-column>
      <el-table-column label="紧急联系人电话" prop="jhr1phone" min-width="150px"></el-table-column>
      <el-table-column label="预警原因" prop="title" min-width="150px"></el-table-column>

<!--      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini">查看</el-tag>
        </template>
      </el-table-column>-->
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" class="cus_dialog" title="打卡详情" :visible.sync="dialogShow" :close-on-click-modal="false"
      custom-class="cus_dialog" width="80%">
      请选择打卡月份：
      <el-date-picker size="small" style="width:150px;margin-right:10px" v-model="detailMonth" value-format="yyyy-MM"
        type="month" placeholder="选择月" @change="reloadLog"></el-date-picker>

      <el-table v-tableFit :data="signLogs" row-key="id" border stripe style="margin-top:10px;">
        <el-table-column type="index" label="序号" width="60px"></el-table-column>
        <el-table-column label="姓名" prop="stu_name" width="100px"></el-table-column>
        <el-table-column label="所在班级" prop="class_name" show-overflow-tooltip width="150px"></el-table-column>
        <el-table-column label="系部" prop="class_name" show-overflow-tooltip min-width="150px">
          <template slot-scope="scope">{{ scope.row.yx_name }} {{ scope.row.major_name }}</template>
        </el-table-column>
        <el-table-column label="打卡时间" prop="sign_time" width="200px">

        </el-table-column>
        <el-table-column label="打卡类型" prop="remark" width="150px">
          <template slot-scope="scope">{{ scope.row.remark ? scope.row.remark : '正常打卡' }}</template>
        </el-table-column>

        <el-table-column label="详细地址" prop="remark" show-overflow-tooltip min-width="150px">
          <template slot-scope="scope">{{ scope.row.sign_address }}</template>
        </el-table-column>

        <el-table-column label="打卡图片" prop="dk_status" width="120px">
          <template slot-scope="scope">
            <el-image v-if="scope.row.pic" style="width: 100px;height:40px;" :src="scope.row.pic" :preview-src-list="[scope.row.pic]"
              fit="contain" :z-index="3000" />
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 请假详情 -->
    <el-dialog title="请假详情" :visible.sync="showLeaveDialog" :close-on-click-modal="false" custom-class="cus_dialog"
      width="80%">
      <el-table v-tableFit :data="leaveList" row-key="id" border stripe style="margin-top:10px;">
        <el-table-column prop="stu_name" label="学生姓名" width="100" align="center" />
        <el-table-column prop="class_name" label="班级" show-overflow-tooltip width="130" align="center" />
        <el-table-column prop="title" label="标题" show-overflow-tooltip width="180" align="center" />


        <el-table-column prop="leave_type" label="类型" show-overflow-tooltip width="100" align="center" />
        <el-table-column prop="leave_num" label="请假天数" show-overflow-tooltip width="100" align="center" />


        <el-table-column prop="cdate" label="请假时间" show-overflow-tooltip width="240">
          <template slot-scope="scope">
            <span style="color: blue;">{{ scope.row.leave_start }}</span>

            <span style="color: blue;"> ~</span><br />
            <span style="color: blue;">{{ scope.row.leave_end }}</span>

          </template>

        </el-table-column>
        <el-table-column prop="leave_content" label="请假原因" show-overflow-tooltip width="160"
          align="center"></el-table-column>

        <el-table-column prop="cdate" label="提交时间" show-overflow-tooltip width="200" align="center" />

        <el-table-column prop="sp_username" label="审批人" width="90" align="center" />


      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../utils/util";
import tchtree from "../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {
      treeData: [],
      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true
      },
      searchForm: {
        class_ids: "",
        ktype: "stu_name",
        keyword: "",
        dk: "",
        date: util.formatDate(),
        year_week: new Date(),
        weekStart: "",
        weekEnd: ""

      },
      tableData: [],
      dialogShow: false,
      modalTitle: "添加",
      formData: {},
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假"],
      qyxz: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 11,
        total_page: 0
      },
      overlap: null,
      spword: "",
      signLogs: [],
      signLevel: "",//打卡类型，1=1天1次，2=1天两次，week=一周一次
      detailMonth: this.$moment().format("YYYY-MM"),
      ckdStuid: 0,
      showLeaveDialog: false,
      leaveList: []
    }
  },
  mounted() {
    this.getList();

  },
  methods: {

    changeDate(e) {

      let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
      this.searchForm.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
      this.searchForm.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

    },

    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    formAdd() {
      this.dialogForm = true;
      this.modalTitle = "添加";
      this.formData = {};
    },
    getList() {
      let data = {};

      data.dk = this.searchForm.dk + "";
      data.class_ids = this.searchForm.class_ids;
      data.sign_date = this.searchForm.date;
      data.page = this.page.current_page
      data.keyword = this.searchForm.keyword;
      data.sign_start = this.searchForm.weekStart;
      data.sign_end = this.searchForm.weekEnd;

      this.$http.post("/api/sign_warning_list", data).then(res => {
        this.tableData = res.data.data;
        this.signLevel = res.data.dk_lvl
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$http
        .post("/api/t_sch_terms_delete", {
          id: row.id
        })
        .then(res => {
          this.$message.success("删除成功");
          this.getList();
        });
    },
    handleModify(row) {
      this.ckdStuid = row.id
      this.detailMonth = this.$moment(this.searchForm.date).format('YYYY-MM')
      this.$http
        .post("/api/get_clock_in_log", {
          stu_id: row.id,
          sign_date: this.searchForm.date,
          sign_start: this.detailMonth + "-01",
          sign_end: this.$moment(this.detailMonth + "-01").endOf('months').format('YYYY-MM-DD')

        })
        .then(res => {
          this.signLogs = res.data;
          this.dialogShow = true;
        });
    },
    reloadLog() {
      this.$http
        .post("/api/get_clock_in_log", {
          stu_id: this.ckdStuid,
          sign_date: this.searchForm.date,
          sign_start: this.detailMonth + "-01",
          sign_end: this.$moment(this.detailMonth + "-01").endOf('months').format('YYYY-MM-DD')

        })
        .then(res => {
          this.signLogs = res.data;
          this.dialogShow = true;
        });

    },
    submitForm(st) {
      this.$confirm("确定审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .post("/api/t_sch_stu_enter_check", {
            id: this.formData.id,
            check_status: st,
            check_reason: this.spword
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "审核成功!"
            });
            this.getList();
            this.dialogForm = false;
          });
      });
    },
    uploadFiles(e) {
      this.$http
        .post("/api/import_stu_enter_xls", {
          url: e.src
        })
        .then(res => {
          this.$message.success("导入成功");
          if (res.data.errmsg && res.data.errmsg.length > 0) {
            let html = "";
            for (let msg of res.data.msg) {
              html +=
                "<div style='font-size:12px'>" +
                msg["姓名"] +
                ":" +
                msg.msg +
                "</div>";
            }
            this.$alert(html, "未导入数据", {
              dangerouslyUseHTMLString: true
            });
          }
          this.getList();
        });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_sign_record", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    showLeaveDetail(row) {
      this.$http
        .post("/api/stu_leave_one", {
          id: row.leaveid
        })
        .then(res => {
          this.leaveList = [res.data];
          console.log(this.leaveList)
          this.showLeaveDialog = true;
        });
    },
    exportExcel() {
      this.$http.post('/api/export_dkyj_excel', {
        class_ids: this.searchForm.class_ids,
        pmid: this.$store.state.pmid,
        uid: this.$store.state.teacher.id
      }).then(res => {
        if (res.data.url) {
          window.open(res.data.url);
        }
      })
    },
    handleSearch() {
      this.page.current_page = 1
      this.getList()
    },
  }
};
</script>

<style lang="less" scoped></style>